const questions = [
    {
        "question": "Pregunta 1",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 2",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 3",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 4",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 5",
        "correct_answer": 4
    },
    {
        "question": "Pregunta 6",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 7",
        "correct_answer": 3
    },
    {
        "question": "Pregunta 8",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 9",
        "correct_answer": 1
    },
    {
        "question": "Pregunta 10",
        "correct_answer": 4
    },
    {
        "question": "Pregunta 11",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 12",
        "correct_answer": 1
    },
    {
        "question": "Pregunta 13",
        "correct_answer": 1
    },
    {
        "question": "Pregunta 14",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 15",
        "correct_answer": 4
    },
    {
        "question": "Pregunta 16",
        "correct_answer": 2
    },
    {
        "question": "Pregunta 17",
        "correct_answer": 3
    },
    {
        "question": "Pregunta 18",
        "correct_answer": 1
    },
    {
        "question": "Pregunta 19",
        "correct_answer": 1
    },
    {
        "question": "Pregunta 20",
        "correct_answer": 3
    }
]

export default questions;